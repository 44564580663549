import { db, storage } from "../config/firebaseConfig";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { Shop } from "../model/Shop";
import {
  deleteObject,
  getDownloadURL,
  list,
  ref,
  uploadBytes,
} from "firebase/storage";
import { Deal } from "../model/Deal";
import { User } from "../model/User";

export const getShops = async (): Promise<Shop[]> => {
  try {
    const shopsCollection = collection(db, "shops");
    const q = query(shopsCollection, orderBy("createdAt", "desc"));
    const shopsSnapshot = await getDocs(q);
    const shops: any[] = [];
    for (const shop of shopsSnapshot.docs) {
      const docRef = doc(db, "users", shop.data().manager);
      const docSnap = await getDoc(docRef);
      shops.push({
        id: shop.id,
        ...shop.data(),
        manager: docSnap.exists() ? (docSnap.data() as User) : undefined,
      });
    }
    return shops;
  } catch (error) {
    console.error("Error fetching shops:", error);
    return [];
  }
};

export const getShop = async (id: string): Promise<Shop | null> => {
  try {
    const shopDocRef = doc(db, "shops", id);
    const shopDoc = await getDoc(shopDocRef);
    if (shopDoc.exists()) {
      const docRef = doc(db, "users", shopDoc.data().manager);
      const docSnap = await getDoc(docRef);
      return {
        id: shopDoc.id,
        ...shopDoc.data(),
        manager: docSnap.exists() ? (docSnap.data() as User) : undefined,
      } as Shop;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching shop by ID:", error);
    return null;
  }
};

export const insertShop = async (shop: Shop): Promise<string | undefined> => {
  try {
    const copy = {
      ...shop,
      createdAt: Timestamp.now(),
      manager: shop.manager.uid,
    } as any;
    delete copy.logoFile;
    const docRef = await addDoc(collection(db, "shops"), copy);
    return docRef.id;
  } catch (error) {
    console.error("Error updating shop:", error);
  }
};

export const updateShop = async (shop: Shop): Promise<void> => {
  try {
    const copy = { ...shop, manager: shop.manager.uid } as any;
    delete copy.logoFile;
    const shopDocRef = doc(db, "shops", copy.id);
    await updateDoc(shopDocRef, copy);
  } catch (error) {
    console.error("Error insert shop:", error);
  }
};

export const uploadFile = async (
  file: File,
  id: string,
  collection: string,
): Promise<string | undefined> => {
  if (file !== undefined) {
    const regex = /(?:\.([^.]+))?$/;
    const newFile = new File([file], id + "." + regex.exec(file.name)![1], {
      type: file.type,
    });
    const fileRef = ref(storage, collection + newFile.name);
    const allImage = await list(ref(storage, collection));
    const imageName = allImage.items
      .map((item) => item.name)
      .find((name) => name.match(id));
    if (imageName) {
      await deleteObject(ref(storage, collection + imageName));
    }
    await uploadBytes(fileRef, newFile);
    return await getDownloadURL(fileRef);
  }
  return;
};

export const fetchDeals = async (shopId: string): Promise<Deal[]> => {
  try {
    const q = query(
      collection(db, "deals"),
      where("shop", "==", shopId),
      orderBy("createdAt", "desc"),
    );
    const querySnapshot = await getDocs(q);
    const dealsData: Deal[] = [];
    querySnapshot.forEach((doc) => {
      dealsData.push({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate
          ? doc.data().startDate.toDate()
          : undefined,
        endDate: doc.data().endDate ? doc.data().endDate.toDate() : undefined,
      } as Deal);
    });
    return dealsData;
  } catch (error) {
    console.error("Error fetching deals : ", error);
    return [];
  }
};

export const insertDeal = async (deal: any): Promise<string | undefined> => {
  try {
    const copy = { ...deal, createdAt: Timestamp.now() };
    delete copy.imageFile;
    const docRef = await addDoc(collection(db, "deals"), copy);
    await updateDeal({ ...deal, id: docRef.id });
    return docRef.id;
  } catch (error) {
    console.error("Error insert deal:", error);
  }
};

export const updateDeal = async (deal: any): Promise<void> => {
  try {
    const copy = { ...deal };
    delete copy.imageFile;
    const shopDocRef = doc(db, "deals", copy.id);
    await updateDoc(shopDocRef, copy);
  } catch (error) {
    console.error("Error updating deal:", error);
  }
};

export const deleteDeal = async (dealId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, "deals", dealId));
  } catch (error) {
    console.error("Error delete deal:", error);
  }
};
