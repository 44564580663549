import React from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useUserEditor } from "../../hook/useUserEditor";
import { ManagerInfoForm } from "./ManagerInfoForm";

export function UserView() {
  const { id } = useParams<{ id: string }>();
  const {
    user,
    setUser,
    handleSave,
    validateField,
    showSnackbar,
    errors,
    setShowSnackbar,
    userIsValid,
    emailAlreadyExist,
  } = useUserEditor(id);
  return (
    <Box sx={{ width: "100%" }}>
      <Container sx={{ my: 5 }} maxWidth={"xl"}>
        {user?.firstName && user.lastName && (
          <Typography
            variant="h5"
            component="div"
            marginBottom={2}
            color="primary"
          >
            {user.firstName} {user.lastName}
          </Typography>
        )}
        {!!user && (
          <ManagerInfoForm
            setUser={setUser}
            user={user!}
            errors={errors}
            validateField={validateField}
          />
        )}
        {showSnackbar && (
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => setShowSnackbar(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setShowSnackbar(false)}
              severity={
                userIsValid(errors) && !emailAlreadyExist ? "success" : "error"
              }
              variant="filled"
            >
              {emailAlreadyExist
                ? "Email déjà utilisé."
                : userIsValid(errors)
                  ? "Enregistrement des données de l'utilisateur."
                  : "Veuillez remplir tous les champs obligatoires."}
            </Alert>
          </Snackbar>
        )}
        <Grid
          container
          justifyContent="flex-end"
          sx={{
            position: "fixed",
            bottom: 50,
            left: -50,
            width: "100%",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </Grid>
      </Container>
    </Box>
  );
}
