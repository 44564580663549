export interface User {
  uid: string;
  firstName: string;
  lastName: string;
  managerEmail: string;
  managerPhone: string;
  role: UserRole;
}

export enum UserRole {
  ADMIN = "admin",
  MANAGER = "manager",
}

export const UserRoleValue = {
  [UserRole.ADMIN]: "Administrateur",
  [UserRole.MANAGER]: "Commerçant",
};
