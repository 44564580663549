import { db } from "../config/firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { User } from "../model/User";

export const getUsers = async (): Promise<User[]> => {
  try {
    const shopsCollection = collection(db, "users");
    const q = query(shopsCollection, orderBy("managerEmail", "desc"));
    const usersSnapshot = await getDocs(q);
    const users: User[] = [];
    usersSnapshot.forEach((doc) => {
      users.push({
        uid: doc.id,
        ...doc.data(),
      } as User);
    });
    return users;
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

export const getUser = async (id: string): Promise<User | null> => {
  try {
    const userDocRef = doc(db, "users", id);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return {
        ...userDoc.data(),
      } as User;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching shop by ID:", error);
    return null;
  }
};

export const insertUser = async (user: User): Promise<string | undefined> => {
  try {
    if (user.managerEmail) {
      const uid = await createUserByAdmin(user.managerEmail);
      if (uid) {
        const copy = {
          ...user,
          uid: uid,
        };
        await setDoc(doc(db, "users", uid), copy);
        return uid;
      }
      return;
    }
  } catch (error) {
    console.error("Error insert user:", error);
  }
};

export const updateUser = async (user: User): Promise<void> => {
  try {
    const copy = { ...user };
    const shopDocRef = doc(db, "users", copy.uid);
    await updateDoc(shopDocRef, copy);
  } catch (error) {
    console.error("Error updating user:", error);
  }
};

async function createUserByAdmin(email: string) {
  const response = await fetch(`http://localhost:5245/create-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Indique que tu envoies des données JSON
    },
    body: JSON.stringify({ email }), // Envoie l'e-mail comme données JSON
  });
  if (!response.ok) {
    const errorData = await response.json();
    console.error("Erreur lors de la création de l'utilisateur:", errorData);
    return;
  }
  const data = await response.json();
  return data.uid as string;
}
