import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Deal } from "../../model/Deal";
import React, { useState } from "react";
import { DealEditor } from "./DealEditor";
import {
  deleteDeal,
  insertDeal,
  updateDeal,
  uploadFile,
} from "../../service/ShopService";
import { Delete as DeleteIcon } from "@mui/icons-material";

interface ShopDealsProps {
  deals: Deal[];
  shopId: string;
  fetchDeals: () => void;
}

const truncateDescription = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export function ShopDeals({ deals, fetchDeals, shopId }: ShopDealsProps) {
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const handleDealClick = (deal: Deal) => {
    setSelectedDeal(deal);
    setIsCreating(false);
    setEditorOpen(true);
  };
  const handleOnSave = async (deal: Deal) => {
    const copyDeal = {
      ...deal,
      startDate: deal.startDate ?? null,
      endDate: deal.endDate ?? null,
    };
    if (isCreating) {
      const url = await uploadFile(copyDeal.imageFile!, copyDeal.id, "deals/");
      await insertDeal({ ...copyDeal, image: url ? url : copyDeal.image });
    } else {
      const url = await uploadFile(copyDeal.imageFile!, copyDeal.id, "deals/");
      await updateDeal({ ...copyDeal, image: url ? url : copyDeal.image });
    }
    fetchDeals();
    handleCloseEditor();
  };
  const handleCloseEditor = () => {
    setEditorOpen(false);
    setIsCreating(false);
    setSelectedDeal(null);
  };
  const handleCreateDeal = () => {
    const emptyDeal: Deal = {
      id: "",
      title: "",
      description: "",
      shop: shopId,
      categories: [],
      tags: [],
      active: false,
      image: "",
    };
    setSelectedDeal(emptyDeal);
    setIsCreating(true);
    setEditorOpen(true);
  };
  const handleDeleteDeal = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    await deleteDeal(id);
    fetchDeals();
  };
  return (
    <>
      <Grid container justifyContent="flex-end" marginBottom={5}>
        <Button variant="contained" color="primary" onClick={handleCreateDeal}>
          Créer une promotion
        </Button>
      </Grid>

      <Grid container spacing={3}>
        {deals.map((deal) => (
          <Grid item xs={12} sm={6} md={4} key={deal.id}>
            <Card onClick={() => handleDealClick(deal)}>
              {deal.image && (
                <CardMedia
                  component="img"
                  height="140"
                  image={deal.image}
                  alt={deal.title}
                />
              )}
              <CardContent>
                <Typography variant="h5" component="div">
                  {deal.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {deal.description
                    ? truncateDescription(deal.description, 100)
                    : ""}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  marginTop={2}
                >
                  {"Date de début: " +
                    (deal.startDate ? deal.startDate.toLocaleString() : "")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {"Date de fin: " +
                    (deal.endDate ? deal.endDate.toLocaleString() : "")}
                </Typography>
                <Typography
                  variant="body2"
                  color={deal.active ? "green" : "red"}
                >
                  {deal.active ? "Active" : "Inactive"}
                </Typography>
                <Grid container justifyContent="flex-end">
                  <IconButton
                    color="error"
                    onClick={(event) => handleDeleteDeal(deal.id, event)}
                  >
                    <DeleteIcon style={{ fontSize: "1.5rem" }} />
                  </IconButton>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {isEditorOpen && selectedDeal && (
        <DealEditor
          deal={selectedDeal}
          onClose={handleCloseEditor}
          onSave={handleOnSave}
        />
      )}
    </>
  );
}
