import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import "./ShopDataGrid.css";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { User } from "../model/User";

const columns: GridColDef[] = [
  { field: "uid", headerName: "ID", flex: 1 },
  {
    field: "firstName",
    headerName: "Prénom",
    flex: 1,
  },
  {
    field: "lastName",
    headerName: "Prénom",
    flex: 1,
  },
  { field: "managerEmail", headerName: "Email", flex: 1 },
  {
    field: "managerPhone",
    headerName: "Téléphone",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { managerPhone } = params.row;
      return <span>{`${managerPhone}`}</span>;
    },
  },
  {
    field: "role",
    headerName: "Role",
    flex: 1,
  },
];

export function UserDataGrid({ users }: { users: User[] }) {
  const navigate = useNavigate();
  const rows = users.map((user) => ({
    id: user.uid,
    ...user,
  }));
  const handleRowClick = (param: any) => {
    navigate(`/user/edit/${param.id}`);
  };

  return (
    <Grid style={{ height: "calc(100vh - 64px)", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[5, 10, 20]}
        disableRowSelectionOnClick
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "primary.main",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        onRowClick={handleRowClick}
      />
    </Grid>
  );
}
