import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { User, UserRole, UserRoleValue } from "../../model/User";

type GeneralInfoFormProps = {
  user: User;
  setUser: (user: User) => void;
  errors: any;
  validateField: (name: string, value: string) => void;
};

export function ManagerInfoForm({
  user,
  setUser,
  errors,
  validateField,
}: GeneralInfoFormProps) {
  const handleManagerChange = (e: any) => {
    const { name, value } = e.target;
    validateField(name, value);
    setUser({ ...user, [name]: value });
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom mb={2}>
          Information du commerçant
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Prénom du commerçant"
              name="firstName"
              value={user.firstName}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.firstName}
              helperText={errors.firstName ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nom du commerçant"
              name="lastName"
              value={user.lastName}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.lastName}
              helperText={errors.lastName ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Téléphone administratif"
              name="managerPhone"
              value={user.managerPhone}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.managerPhone}
              helperText={errors.managerPhone ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-mail administratif"
              name="managerEmail"
              value={user.managerEmail}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.managerEmail}
              helperText={errors.managerEmail ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              margin="none"
              required
              error={errors.role}
            >
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="status-label"
                id="role"
                name="role"
                value={user.role}
                onChange={handleManagerChange}
                label="Role"
                variant="outlined"
              >
                {Object.entries(UserRole).map(([userKey, userValue]) => (
                  <MenuItem key={userKey} value={userValue}>
                    {UserRoleValue[userValue]}
                  </MenuItem>
                ))}
              </Select>
              {errors.status && (
                <FormHelperText>Ce champ est obligatoire</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
