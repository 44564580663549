import { getUsers } from "../service/UserService";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { User } from "../model/User";
import { UserDataGrid } from "../components/UserDataGrid";

export default function UserList() {
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getUsers().then((users) => setUsers([...users]));
  }, []);
  const handleClick = () => {
    navigate(`/user/create`);
  };
  return (
    <>
      <Box sx={{ textAlign: "right", m: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          className="ce"
        >
          Créer un utilisateur
        </Button>
      </Box>
      <UserDataGrid users={users} />
    </>
  );
}
