import { Shop } from "../../model/Shop";
import { Grid } from "@mui/material";
import { ShopInfoForm } from "./ShopInfoForm";
import { LogoUploader } from "./LogoUploader";
import React from "react";

interface ShopEditorProps {
  shop: Shop;
  setShop: React.Dispatch<React.SetStateAction<Shop | undefined>>;
  errors: any;
  validateField: (name: string, value: string) => void;
}

export function ShopEditor({
  shop,
  setShop,
  errors,
  validateField,
}: ShopEditorProps) {
  const handleDrop = (acceptedFiles: File[]) => {
    setShop({ ...shop, logoFile: acceptedFiles[0] } as Shop);
  };

  return (
    <>
      {!!shop && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ShopInfoForm
                shop={shop}
                setShop={setShop}
                errors={errors}
                validateField={validateField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid>
                <LogoUploader
                  logo={
                    shop.logoFile !== undefined
                      ? URL.createObjectURL(shop.logoFile)
                      : shop.logo!
                  }
                  onDrop={handleDrop}
                  title={"Logo du magasin"}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
