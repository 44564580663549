import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, insertUser, updateUser } from "../service/UserService";
import { User, UserRole } from "../model/User";

const emptyUser: User = {
  uid: "",
  firstName: "",
  lastName: "",
  managerEmail: "",
  managerPhone: "",
  role: UserRole.MANAGER,
};

export function useUserEditor(id: string | undefined) {
  const [user, setUser] = useState<User>();
  const [emailAlreadyExist, setEmailAlreadyExist] = useState<boolean>();
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    managerEmail: false,
    managerPhone: false,
    role: false,
  });
  const navigate = useNavigate();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const userIsValid = (errors: any) => {
    return !Object.values(errors).some((error) => error);
  };
  const validateField = (name: string, value: string) => {
    setErrors({
      ...errors,
      [name]: value.trim() === "",
    });
  };
  const handleSave = async () => {
    setEmailAlreadyExist(false);
    const newErrors = {
      firstName: user?.firstName.trim() === "",
      lastName: user?.lastName.trim() === "",
      managerEmail: user?.managerEmail.trim() === "",
      managerPhone: user?.managerPhone.trim() === "",
      role: user?.role.trim() === "",
    };
    setErrors(newErrors);
    if (userIsValid(newErrors)) {
      if (user?.uid) {
        await updateUser(user);
      } else if (user) {
        const newId = await insertUser(user);
        if (newId) {
          console.log("fdfs");
          navigate(`/user/edit/${newId}`);
        } else {
          setEmailAlreadyExist(true);
        }
        setShowSnackbar(true);
      }
    }
  };

  useEffect(() => {
    if (id) {
      console.log(id);
      getUser(id).then((user) => {
        console.log(user);
        if (user !== null) {
          setUser(user);
        } else {
          navigate("/user");
        }
      });
    } else {
      setUser(emptyUser);
    }
    // eslint-disable-next-line
  }, [id]);
  return {
    handleSave,
    validateField,
    showSnackbar,
    errors,
    setShowSnackbar,
    user,
    setUser,
    userIsValid,
    emailAlreadyExist,
  };
}
