import { useEffect, useState } from "react";
import { Shop, ShopStatus } from "../model/Shop";
import { useNavigate } from "react-router-dom";
import {
  fetchDeals,
  getShop,
  insertShop,
  updateShop,
  uploadFile,
} from "../service/ShopService";
import { Deal } from "../model/Deal";
import { UserRole } from "../model/User";

const emptyShop: Shop = {
  id: "",
  name: "",
  phone: "",
  email: "",
  manager: {
    uid: "",
    firstName: "",
    lastName: "",
    managerEmail: "",
    managerPhone: "",
    role: UserRole.MANAGER,
  },
  address: {
    city: "",
    zipCode: "",
    street: "",
    country: "",
    latitude: 0,
    longitude: 0,
  },
  businessDays: {
    monday: { open: false, businessHours: [] },
    tuesday: { open: false, businessHours: [] },
    wednesday: { open: false, businessHours: [] },
    thursday: { open: false, businessHours: [] },
    friday: { open: false, businessHours: [] },
    saturday: { open: false, businessHours: [] },
    sunday: { open: false, businessHours: [] },
  },
  status: ShopStatus.PENDING,
  siret: "",
  logo: "",
};

export function useShopEditor(id: string | undefined) {
  const [shop, setShop] = useState<Shop>();
  const [deals, setDeals] = useState<Deal[]>([]);
  const [errors, setErrors] = useState({
    name: false,
    manager: false,
    address: false,
    status: false,
    siret: false,
  });
  const navigate = useNavigate();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const shopIsValid = (errors: any) => {
    return !Object.values(errors).some((error) => error);
  };
  const validateField = (name: string, value: string) => {
    setErrors({
      ...errors,
      [name]: value.trim() === "",
    });
  };
  const handleSave = async () => {
    setShowSnackbar(true);
    const newErrors = {
      name: shop?.name.trim() === "",
      manager: shop?.manager.uid === "",
      address:
        shop?.address.city.trim() === "" ||
        shop?.address.zipCode.trim() === "" ||
        shop?.address.street.trim() === "" ||
        shop?.address.country.trim() === "",
      status: shop?.status.trim() === "",
      siret: shop?.siret.trim() === "",
    };
    setErrors(newErrors);
    if (shopIsValid(newErrors)) {
      if (shop?.id) {
        const url = await uploadFile(shop.logoFile!, shop.id, "shops/");
        await updateShop({ ...shop, logo: url ? url : shop.logo });
      } else if (shop) {
        const newId = await insertShop(shop);
        if (newId) {
          const url = await uploadFile(shop?.logoFile!, newId!, "shops/");
          await updateShop({
            ...shop,
            logo: url ? url : shop?.logo,
            id: newId,
          }).then(() => {
            navigate(`/edit/${newId}`);
          });
        }
      }
      setShowSnackbar(true);
    }
  };

  const fetchNewDeals = () => {
    fetchDeals(id!).then((deals) => {
      setDeals(deals);
    });
  };
  useEffect(() => {
    if (id) {
      getShop(id).then((shop) => {
        if (shop !== null) {
          setShop(shop);
          fetchDeals(id).then((deals) => {
            setDeals(deals);
          });
        } else {
          navigate("/");
        }
      });
    } else {
      setShop(emptyShop);
    }
    // eslint-disable-next-line
  }, [id]);
  return {
    shop,
    setShop,
    handleSave,
    validateField,
    showSnackbar,
    errors,
    shopIsValid,
    setShowSnackbar,
    deals,
    fetchNewDeals,
  };
}
